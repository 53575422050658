import { Button } from "@material-ui/core";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import { makeStyles } from "@material-ui/core/styles";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { NavLink } from "react-router-dom";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRecentProjects } from "../../actions/projectsActions";
import Spinner from "../Loader/Spinner";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    marginBottom: 20
  },
  gridList: {
    width: "100%",
    minHeight: 150
  },
  GridListTile: {
    textAlign: "center",
    "& >div": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative"
    }
  },
  section: {
    overflow: "hidden",
    transition: "display 0.3s ease-out",
    height: "auto",
    flex: 1,
    display: "flex"
  }
}));

const ImageGridList = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const projects = useSelector(state => state.projects.recent_projects);
  const loading = useSelector(state => state.projects.loading);
  useEffect(() => {
    dispatch(getRecentProjects());
  }, []);
  const getGridListCols = () => {
    if (isWidthUp("md", props.width)) {
      return 3;
    }
    return 2;
  };

  return (
    <div className={classes.root}>
      <h1 style={{ textAlign: "center", margin: "20px auto" }}>
        פרויקטים אחרונים
      </h1>
      <GridList
        cellHeight={140}
        className={classes.gridList}
        cols={getGridListCols()}
      >
        {Object.keys(projects).length === 0 || loading ? (
          <Spinner />
        ) : (
          projects.map(project => (
            <GridListTile
              key={project._id}
              className={classes.GridListTile}
              style={{
                height: 180
              }}
            >
              <img src={project.cover_image} alt={project.name} />
              <div className="tetststts">
                <div className="sectionn">
                  <h4 className={classes.section}>{project.name}</h4>
                  <div className="collapsedd">
                    <p style={{ maxHeight: 40, overflow: "hidden" }}>
                      {project.about}
                    </p>
                    <Button
                      component={NavLink}
                      to={`/projects/${project._id}`}
                      variant="contained"
                      color="primary"
                      style={{ margin: "20px auto" }}
                    >
                      הצג פרויקט
                    </Button>
                  </div>
                </div>
              </div>
            </GridListTile>
          ))
        )}
      </GridList>

      <Button
        component={NavLink}
        to="/projects?category=all"
        variant="contained"
        color="primary"
        style={{ margin: "20px auto" }}
      >
        כל הפרויקטים
      </Button>
    </div>
  );
};

export default withWidth()(ImageGridList);
