import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Link } from "react-router-dom";
import wide from "../../images/aboutus2.jpg";
import Logo from "../../images/logo.png";

const useStyles = makeStyles(theme => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  cardMedia: {
    paddingTop: "56.25%" // 16:9
  },
  cardContent: {
    flexGrow: 1
  },
  gridItemFit: {
    width: "100%"
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper
    //padding: theme.spacing(8, 0, 6)
  },
  paper: {
    margin: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  formControl: {
    padding: theme.spacing(4, 4)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  widthFit: {
    width: "100%"
  },
  input: {
    display: "none"
  },
  pargraph: {
    margin: " 0 0 10px",
    boxShadow: "border-box"
  },
  spacing: { margin: theme.spacing(2) }
}));


export default function Album() {
  const classes = useStyles();



  
  return (
    <React.Fragment>
      <main>
        <Container
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            minWidth: "100%",
            padding: 0
          }}
        >
          <img src={wide} style={{ width: "100%" }} alt="holder"></img>
          <Typography
            component="h1"
            variant="h3"
            align="center"
            style={{ position: "absolute", color: "white" }}
            gutterBottom
          >
            קצת עלינו...
          </Typography>
        </Container>

        <Container maxWidth="lg" className={classes.formControl}>
          <CssBaseline />
          <div className={classes.paper}>
            <Typography component="h1" variant="h3">
              אודות החברה
            </Typography>
          </div>
          <Typography component="p" variant="body1">
            <hr />
            <br />
            <div>
              חברת מחאמיד תופיק בע"מ הוקמה בשנת 1984 ע"י מחאמיד תופיק. מחאמיד
              תופיק הינה חברת הבניה בעלת מוניטין , אמינות ומקצועיות מהידועות
              בארץ. החברה בונה בכל קצוות הארץ.
            </div>

            <div>
              <p className={classes.pargraph}>
                <h3>התמחות החברה:</h3>
              </p>
              <p className={classes.pargraph}>
                <strong>1 - מבני ציבור:</strong> בתי ספר, קריות חינוך, בתי ספר
                והיכלי ספורט, קמפוסים לאוניברסטאות, מכללות.
              </p>
              <p className={classes.pargraph}>
                <strong>2 - למגזר הממשלתי:</strong> תחנות רכבת.
              </p>
              <p className={classes.pargraph}>
                <strong>3 - למגזר הפרטי:</strong> בנייה רוויה, צמודי קרקע ומגדלי
                מגורים.
              </p>
              <br />
              <p className={classes.pargraph}>
                בחברה מועסקים מהנדסים, מנהלי פרויקטים, מנהלי עבודה ועובדים, שהם
                מהטובים בשוק העבודה הארצי. לחברה אגף להבטחת ובקרת איכות. החברה
                מציעה לקהל לקוחותיה איכות עבודה ומעקב מאוד מסודר על תהליכי
                הביצוע והמסירות. החברה שומרת על כללי הבטיחות בעבודה ע&quot;י
                חברת ממוני בטיחות מקצועיים ואמינים ובונה מודלים ומעקבים יומיים
                על כללי הבטיחות.
              </p>
              <p className={classes.pargraph}>
                חברת מחאמיד תופיק מעסיקה קבלנים ובעלי עסק רשומים , תעודות רישום
                מוכרות מהמדינה. ספקי החברה הם בעלי תוו תקן עם איכות מוצרים
                מהטובים בשוק הארצי והעולמי.
              </p>
              <p className={classes.pargraph}>
                <strong>הפרויקטים שהקימה החברה למשק וכלכלה:</strong>
              </p>
              <ul>
                <li>קריות חינוך בכפר סבא.</li>
                <li>3 קריות חינוך במודיעין .</li>
                <li>4 קריות חינוך ברחובות .</li>
                <li>3 קריות חינוך בנס ציונה.</li>
              </ul>
              <p className={classes.pargraph}>
                קריות חינוך שכוללים בין היותר בתי ספר, כיתות לימוד, מעבדות,
                מגרשים ואולמי ספורט, מעונות וכל עבודות הפיתוח הנלוות.
              </p>
              <p className={classes.pargraph}>
                חב' מחאמיד תופיק הקימה פרויקטים לאומיים גדולים, כמו כיכר העיר
                במודיעין אשר כללו רחבות, מנהרות, חיבור גשרונים והתחברות בין 4
                פרויקטים גדולים.
              </p>
              <p className={classes.pargraph}>
                החברה הקימה 3 תחנות רכבות אשר כוללות רציפים, מבנים ומעברים
                עיליים ותת קרקעיים, חניונים גשרונים ומנהרות.
              </p>
              <p className={classes.pargraph}>
                החברה גם הקימה קמפוסים ומבני מדעים כמו מכללת אלקאסמי משנת 2002
                בשטח של 40,000 מ&quot;ר מבנים אשר כוללים חניונים תת קרקעיים,
                מבני הנהלה, מבני כיתות, מבני מעבודת מתקדמות, מבני מעבדות
                טכנולוגיות מתקדמות מבני מעבדות לפיסיקה ביולוגיה וכימיה בגודל
                כ-10,000 מ&quot;ר.
              </p>
              <ul>
                <li>
                  מכללת רופין אשר כוללת כיתות לימוד, מעבדות, חדרי הנהלה ב-10,000
                  מ&quot;ר.
                </li>
                <li>
                  היכלי ספורט ותרבות ענקיים, כמו היכל ספורט מוזה בחוף הכרמל, בקה
                  אלגרביה.
                </li>
                <li>
                  קריות חינוך בנין, נתניה, אולם ספורט לאומי ברמז &#8211; כפר
                  סבא.
                </li>
                <li>
                  אולם ספורט מאושר ליגת על &#8211; כפר סבא , אולם ספורט נס
                  ציונה.
                </li>
              </ul>
              <p className={classes.pargraph}>
                <strong>החברה גם מקימה פרויקטים בבנייה רוויה:</strong>
              </p>
              <ul>
                <li>4,000 יח&quot;ד בחריש.</li>
                <li>1,200 יח&quot;ד בכרמי גת.</li>
                <li>300 יח&quot;ד בנווה זמר-רעננה.</li>
                <li>
                  500 יח&quot;ד צמודי קרקע בקיבוץ המעפיל, נען, געש, משמרות.
                </li>
                <li>
                  238 יח&quot;ד &#8211; בצוותא &#8211; חריש &#8211; שפיר הנדסה
                  אזרחית ויזמית בע&quot;מ.
                </li>
              </ul>
              <p className={classes.pargraph}>
                <strong>פרויקטים בייזום החברה:</strong>
              </p>
              <ul>
                <li>
                  מגדל אירופה &#8211; 126 יח&quot;ד &#8211; יציאת אירופה חיפה.
                </li>
                <li>בניין משרדים &#8211; א.א. פחם.</li>
              </ul>
              <p className={classes.pargraph}>
                החברה הקימה ומקימה עבודות תשתיות ופיתוח לכל אלמנטי הבנייה
                הנ&quot;ל. החברה כיום בהתקשרות עם הגורמים החזקים בשוק כדי לקדם
                פרויקטים ליאומים במשרדים הממשלתיים, משרד השיכון והתחבורה, משרד
                הביטחון ועוד.
              </p>
              <p className={classes.pargraph}>
                בתקופה האחרונה החברה מעסיקה חברות בנלאומיות כקבלני משנה אצלה
                בעבודות שלד, גמרים ותשתיות.
              </p>
              <p className={classes.pargraph}>
                מונחים בפני החברה כעשרה פרויקטים בשיטת POT אשר כוללים ערים
                יישובים שלמים, מבני ציבור כמו עיריות ומכללות, כבישים, מנהרות
                וגשרים. בבעלות החברה בחו&quot;ל ג מפעלי יצור אלמנטי בטון, ברזל
                וכלי עבודה. בבעלות החברה גם מבנים כמו אולמות מופעים, מבנים
                להשכרה ומשרדים מפוארים. החברה היום מתכננת להקים מבני דיור בבנייה
                רוויה ומגדלי מגורים כדי לפתור בעיית הדיור במגזר הערבי.
              </p>
            </div>
          </Typography>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              padding: "5%"
            }}
          >
            <img
              src={Logo}
              style={{ margin: "0 auto", maxWidth: "100%" }}
              alt="logo"
            ></img>
          </div>
        </Container>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            background: "#dcdcdc",
            padding: 20
          }}
        >
          <Typography component="h4" variant="h4" color="black" className={classes.spacing}>
            קבלנים ומנהלי בנייה משנת 1984
          </Typography>
          <Typography component="h5" variant="h5" style={{color:"#4e4e4e"}} className={classes.spacing}>
            חברת מחאמיד תופיק בע"מ הוקמה בשנת 1984 ע"י מחאמיד תופיק.
          </Typography>

          <Button
            component={Link}
            to="/contact-us"
            variant="contained"
            color="primary"
            style={{ textDecoration: "none", color: "white" }}
            className={classes.spacing}
            size="large"
          >
            צור קשר
          </Button>
        </div>
      </main>
    </React.Fragment>
  );
}
