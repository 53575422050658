import { IconButton, Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import EmailIcon from "@material-ui/icons/Email";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import LocationIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import TwitterIcon from "@material-ui/icons/Twitter";
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../images/logo.png";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  icon: {
    marginRight: theme.spacing(2)
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6)
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  cardMedia: {
    paddingTop: "56.25%" // 16:9
  },
  cardContent: {
    flexGrow: 1
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6)
  },
  FooterListTitle: {
    margin: "20px 20px 20px 0px",
    maxWidth: "90%"
  }
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <a color="inherit" href="http://www.mhamidtawfik.com">
        מחאמיד תאופיק
      </a>{" "}
      {"חברה לבנייה ופיתוח בעמ © "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function NestedGrid() {
  const classes = useStyles();
  /* const dispatch = useDispatch();
  const projects = useSelector(state => state.projects.projects);
  const loading = useSelector(state => state.projects.loading); */

  useEffect(() => {
    //dispatch(getAllProjects());
  }, []);

  return (
    <footer className={classes.root} style={{ background: "#f5f5f5" }}>
      <Container className={classes.cardGrid} maxWidth="lg">
        <Grid container justify="center">
          <Grid
            container
            xs={12}
            sm={6}
            md={4}
           justify="center"
            style={{ maxWidth: 270, margin: "3%" }}
          >
            <List>
              <img src={Logo} className={classes.FooterListTitle} alt="logo" />

              <Typography>
                חברת מחאמיד תופיק בע"מ הוקמה בשנת 1984 ע"י מחאמיד תופיק. מחאמיד
                תופיק הינה חברת הבניה האמינה והמקצועית ביותר בענף. החברה מספקת
                מגוון רחב של סוגי עבודה בצפון הארץ, במרכז ובדרום.
              </Typography>
            </List>
          </Grid>
          <Grid
            container
            xs={12}
            sm={6}
            md={4}
            justify="center"
            style={{ maxWidth: 270, margin: "3%" }}
          >
            <List>
              <h2 className={classes.FooterListTitle}>צרו קשר</h2>
              <ListItem key="test1">
                <ListItemIcon>
                  <PhoneIcon />
                </ListItemIcon>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: " column"
                  }}
                >
                  <ListItemText primary="טלפון" />
                  <a href="tel:046115300">04-6115300</a>
                </div>
              </ListItem>

              <ListItem key="test2">
                <ListItemIcon>
                  <EmailIcon />
                </ListItemIcon>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: " column"
                  }}
                >
                  <ListItemText primary="אי-מייל" />
                  <a href="mailTo:office1@m-tawfik.com">office1@m-tawfik.com</a>
                  {/* <ListItemText secondary="office1@m-tawfik.com" /> */}
                </div>
              </ListItem>
              <ListItem key="test3">
                <ListItemIcon>
                  <LocationIcon />
                </ListItemIcon>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column"
                  }}
                >
                  <ListItemText primary="כתובת" />
                  <a
                    href="https://goo.gl/maps/guCvkodfXtskTL2m7"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    אום אל-פחם שכונת אלדהר
                  </a>
                </div>
              </ListItem>
            </List>
          </Grid>

          <Grid
            container
            xs={12}
            sm={6}
            md={4}
            justify="center"
            style={{ maxWidth: 270, margin: "3%" }}
          >
            <List>
              <h2 className={classes.FooterListTitle}>מחאמיד תאופיק</h2>
              <ListItem button key="test1" component={NavLink} to="/projects?category=all">
                <ListItemText primary="פרויקטים" />
              </ListItem>

              <ListItem button key="test4" component={NavLink} to="/about-us">
                <ListItemText primary="אודות" />
              </ListItem>
              <ListItem
                button
                key="test5"
                component={NavLink}
                to="/accessibility"
              >
                <ListItemText primary="הצהרת נגישות " />
              </ListItem>
              <ListItem button key="test6" component={NavLink} to="/contact-us">
                <ListItemText primary="יצירת קשר" />
              </ListItem>
            </List>
          </Grid>
        </Grid>

        <Divider />
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          justify="center"
          spacing={0}
          style={{ marginTop: "3%" }}
        >
          <a
            href="https://www.facebook.com/mhamidtawfik/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton>
              <FacebookIcon />
            </IconButton>
          </a>
          <a
            href="https://www.facebook.com/mhamidtawfik/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton>
              <TwitterIcon />
            </IconButton>
          </a>
          <a
            href="https://www.facebook.com/mhamidtawfik/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton>
              <InstagramIcon />
            </IconButton>
          </a>
          <a
            href="https://www.linkedin.com/company/mhamid-tawfik"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton>
              <LinkedInIcon />
            </IconButton>
          </a>
        </Grid>

        <Grid container justify="center" style={{ marginTop: "3%" }}>
          <Copyright />
        </Grid>
      </Container>
    </footer>
  );
}
