import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Carousel from "react-material-ui-carousel";
import { NavLink } from "react-router-dom";
import image1 from "../../images/1.jpg";
import image2 from "../../images/2.jpg";
import image3 from "../../images/3.jpg";
import Carousal from "./Carousal";
import Categories from "./Categories";
import RecentProjects from "./RecentProjects";
import { Button } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  icon: {
    marginRight: theme.spacing(2)
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6)
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  cardMedia: {
    paddingTop: "56.25%" // 16:9
  },
  cardContent: {
    flexGrow: 1
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6)
  }
}));

function Item(props) {
  return (
    <Paper
      square
      style={{
        maxHeight: 700,
        overflow: "hidden",
        alignItems: "center",
        display: "flex",
        borderRadius: "none",
        position: "relative"
      }}
    >
      <img
        style={{ display: "block", width: "100%" }}
        src={props.item.image}
        alt="slider"
      ></img>

      <Button
        component={NavLink}
        to={`/projects/${props.item.id}`}
        style={{
          position: "absolute",
          top: 20,
          right: 20,
          padding: 5,
          curser: "pointer",
          background:"whitesmoke"
        }}
        variant="contained"
        
        size="large"
      >
        {props.item.name}
      </Button>
    </Paper>
  );
}

function Example(props) {
  var items = [
    {
      id: "5e776aa1435ebe0024001f01",
      name: "מגדל אירופה",
      image: image1
    },
    {
      id: "5e776891435ebe0024001efd",
      name: "רכבת עפולה",
      image: image2
    },
    {
      id: "5e77654f435ebe0024001ef6",
      name: "בית ספר בשוהם",
      image: image3
    }
  ];

  return (
    <Carousel interval={7000} animation={"slide"} indicators={true}>
      {items.map((item, i) => {
        return <Item key={item.id} item={item} />;
      })}
    </Carousel>
  );
}

export default function NestedGrid() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <main>
        <Example />
        <Categories />
        <RecentProjects />
        <Carousal />
      </main>
    </div>
  );
}
