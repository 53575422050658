import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { getAllProjects } from "../../actions/projectsActions";
import Spinner from "../Loader/Spinner";
import FilterBar from "./FilterBar";

const useStyles = makeStyles(theme => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  cardMedia: {
    paddingTop: "56.25%" // 16:9
  },
  cardContent: {
    flexGrow: 1
  },
  gridItemFit: {
    width: "100%"
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4, 0, 6)
  },

  GridListTileBar: {
    position: "absolute",
    right: "10%",
    left: "10%",
    bottom: "auto",
    margin: "auto"
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)"
  },
  GridListTile: {
    textAlign: "center",
    "& >div": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative"
    }
  },
  GridList: {
    margin: "0 !important"
  },
  root: {
    background: "rgb(250, 250, 250)"
  },
  section: {
    overflow: "hidden",
    transition: "display 0.3s ease-out",
    height: "auto",
    flex: 1,
    display: "flex"
  },
  collapsed: {
    display: "none"
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center"
  }
}));

function Album(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const projects = useSelector(state => state.projects.projects);
  const loading = useSelector(state => state.projects.loading);
  const filter = history.location.search.split("=")[1];
  const getGridListCols = () => {
    if (isWidthUp("lg", props.width)) {
      return 3;
    }
    if (isWidthUp("md", props.width)) {
      return 3;
    }
    if (isWidthUp("sm", props.width)) {
      return 2;
    }
    if (isWidthUp("xs", props.width)) {
      return 1;
    }
    return 2;
  };
  useEffect(() => {
    dispatch(getAllProjects());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div className={classes.heroContent} style={{ background: "#fafafa" }}>
          <Container maxWidth="xl">
            <Typography
              component="h1"
              variant="h3"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              פרויקטים
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item className={classes.gridItemFit}>
                  <FilterBar />
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
        <GridList className={classes.GridList} cols={getGridListCols()}>
          {Object.keys(projects).length === 0 || loading ? (
            <Spinner />
          ) : (
            projects.map(project =>
              project.category === filter || filter === "all" ? (
                <GridListTile
                  key={project._id}
                  className={classes.GridListTile}
                  style={{
                    height:
                      120 * getGridListCols() +
                      (getGridListCols() > 1 ? 0 : 190)
                  }}
                >
                  <img src={project.cover_image} alt={project.name} />
                  <div className="tetststts">
                    <div className="sectionn">
                      <h3 className={classes.section}>{project.name}</h3>
                      <div className="collapsedd">
                        <p>{project.about}</p>
                        <Button
                          component={NavLink}
                          to={`/projects/${project._id}`}
                          variant="contained"
                          color="primary"
                          style={{ margin: "20px auto" }}
                        >
                          הצג פרויקט
                        </Button>
                      </div>
                    </div>
                  </div>
                </GridListTile>
              ) : null
            )
          )}
        </GridList>
      </div>
    </React.Fragment>
  );
}
export default withWidth()(Album);

/*
      <CssBaseline />
      <main>
        <div className={classes.heroContent} style={{ background: "#fafafa" }}>
          <Container maxWidth="lg">
            <Typography
              component="h1"
              variant="h3"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              פרויקטים
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item className={classes.gridItemFit}>
                  <FilterBar/>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>

        <Container className={classes.cardGrid} maxWidth="lg">
          <Grid container spacing={3}>
            {Object.keys(projects).length === 0 || loading ? (
              <Spinner />
            ) : (
              projects.map(project => (
                <Grid item key={project._id} xs={12} sm={6} md={4}>
                  <Card className={classes.card}>
                    <CardMedia
                      className={classes.cardMedia}
                      image={project.cover_image}
                      title="Image title"
                    />
                    <CardContent className={classes.cardContent}>
                      <Typography gutterBottom variant="h6" component="h3">
                        {project.name}
                      </Typography>
                      <Typography>{project.about}</Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" color="primary">
                        הצג
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))
            )}
          </Grid>
        </Container>
      </main> */
