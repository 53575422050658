import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import img3 from "../../images/managment/10.jpg";
import img4 from "../../images/managment/4.jpg";
import img6 from "../../images/managment/6.jpg";
import img1 from "../../images/managment/7.jpg";
import img5 from "../../images/managment/8.jpg";
import img2 from "../../images/managment/9.jpg";

const useStyles = makeStyles(theme => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  cardMedia: {
    paddingTop: "120%" // 16:9
  },
  cardContent: {
    flexGrow: 1,
    textAlign: "center"
  },
  gridItemFit: {
    width: "100%"
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6)
  }
}));


export default function Managment() {
  const classes = useStyles();
  const managers = [
    {
      img: img3,
      name: "תופיק מחאמיד",
      position: "נשיא החברה",
      desc: ""
    },
    {
      img: img1,
      name: "מחאמיד מחמוד",
      position: 'מנכ"ל',
      desc: ""
    },
    {
      img: img4,
      name: "חסן מחאמיד",
      position: 'סמנכ"ל',
      desc: ""
    },
    {
      img: img2,
      name: "אחמד מחאמיד",
      position: "ראש הדירקטוריון",
      desc: ""
    },

    {
      img: img5,
      name: "אבראהים מחאמיד",
      position: 'סמנכ"ל',
      desc: ""
    },
    {
      img: img6,
      name: "מוחמד מחאמיד",
      position: 'סמנכ"ל',
      desc: ""
    }
  ];

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        <div className={classes.heroContent} style={{ background: "#fafafa" }}>
          <Container maxWidth="lg">
            <Typography
              component="h1"
              variant="h3"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              האנשים שמובילים את חברת מחאמיד תאופיק
            </Typography>
          </Container>
        </div>

        <Container className={classes.cardGrid} maxWidth="lg">
          <Grid container spacing={3}>
            {managers.map(manager => (
              <Grid item key={manager.name} xs={6} sm={4} md={2}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={manager.img}
                    title={manager.name}
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h6" component="h3">
                      {manager.name}
                    </Typography>
                    <Typography>{manager.position}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
    </React.Fragment>
  );
}
