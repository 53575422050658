import Button from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/NavigateBefore";
import ArrowForwardIosIcon from "@material-ui/icons/NavigateNext";
import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import img1 from "../../images/clients/1.png";
import img10 from "../../images/clients/10.png";
import img11 from "../../images/clients/11.png";
import img12 from "../../images/clients/12.png";
import img13 from "../../images/clients/13.png";
import img14 from "../../images/clients/14.png";
import img15 from "../../images/clients/15.png";
import img16 from "../../images/clients/16.png";
import img17 from "../../images/clients/17.png";
import img18 from "../../images/clients/18.png";
import img19 from "../../images/clients/19.png";
import img2 from "../../images/clients/2.png";
import img20 from "../../images/clients/20.png";
import img3 from "../../images/clients/3.png";
import img4 from "../../images/clients/4.png";
import img5 from "../../images/clients/5.png";
import img6 from "../../images/clients/6.png";
import img7 from "../../images/clients/7.png";
import img8 from "../../images/clients/8.png";
import img9 from "../../images/clients/9.png";

class Carousal extends React.Component {
  state = {
    currentIndex: 0,
    itemsInSlide: 2,
    responsive: {
      1500: { items: 5 },
      1200: { items: 4 },
      800: { items: 3 },
      560: { items: 2 },
      0: { items: 1 }
    },
    galleryItems: this.galleryItems()
  };

  galleryItems() {
    const imagesArray = [
      img1,
      img2,
      img3,
      img4,
      img5,
      img6,
      img7,
      img8,
      img9,
      img10,
      img11,
      img12,
      img13,
      img14,
      img15,
      img16,
      img17,
      img18,
      img19,
      img20
    ];
    return imagesArray.map((item, i) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 200
        }}
      >
        <img
          src={item}
          style={{ display: "block", maxWidth: 180 }}
          alt="לקוח"
        ></img>
      </div>
    ));
  }

  slidePrevPage = () => {
    const currentIndex = this.state.currentIndex - this.state.itemsInSlide;
    this.setState({ currentIndex });
  };

  slideNextPage = () => {
    const {
      itemsInSlide,
      galleryItems: { length }
    } = this.state;
    let currentIndex = this.state.currentIndex + itemsInSlide;
    if (currentIndex > length) currentIndex = length;

    this.setState({ currentIndex });
  };

  handleOnSlideChange = event => {
    const { itemsInSlide, item } = event;
    this.setState({ itemsInSlide, currentIndex: item });
  };

  render() {
    const { currentIndex, galleryItems, responsive } = this.state;

    return (
      <div>
        <h1 style={{ margin: "20px auto", textAlign: "center" }}>
          הלקוחות שלנו
        </h1>
        <div
          style={{
            position: "relative",
            paddingBottom: "4%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <AliceCarousel
            items={galleryItems}
            slideToIndex={currentIndex}
            responsive={responsive}
            onInitialized={this.handleOnSlideChange}
            onSlideChanged={this.handleOnSlideChange}
            onResized={this.handleOnSlideChange}
            buttonsDisabled
            autoPlay={true}
            autoPlayInterval={4000}
            dotsDisabled={true}
          />
          {/*  <button
            style={{ position: "absolute", left: 20, bottom: "56%" }}
            onClick={this.slidePrevPage}
          >
            Prev Page
          </button> */}
          {/* <button
            style={{ position: "absolute", right: 20,bottom:"56%" }}
            onClick={this.slideNextPage}
          >
            Next Page
          </button> */}
          <Button
            style={{
              direction: "ltr",
              position: "absolute",
              left: 25,
              bottom: "50%",
              background: "#f5f5f5",
              border: "1px solid #d4d4d4",
              boxShadow:
                "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)"
            }}
            onClick={this.slidePrevPage}
          >
            <ArrowBackIosIcon />
          </Button>
          <Button
            style={{
              position: "absolute",
              right: 25,
              bottom: "50%",
              background: "#f5f5f5",
              border: "1px solid #d4d4d4",
              boxShadow:
                "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)"
            }}
            onClick={this.slideNextPage}
          >
            <ArrowForwardIosIcon />
          </Button>
        </div>
      </div>
    );
  }
}

export default Carousal;
