import { createMuiTheme, jssPreset, StylesProvider, ThemeProvider } from "@material-ui/core/styles";
import { create } from "jss";
import rtl from "jss-rtl";
import React from "react";
import { Provider } from "react-redux";
import { HashRouter as Router, Route } from "react-router-dom";
import "./App.css";
import AboutUs from "./components/About/AboutUs";
import Managment from "./components/About/Managment";
import Accessibility from "./components/Accessibility/Accessibility";
import ContactUs from "./components/ContactUs/ContactUs";
import Footer from "./components/Layout/Footer";
import Home from "./components/Layout/Home";
import Navigation from "./components/Layout/Navigation";
import Projects from "./components/Projects/Projects";
import Project from "./components/Projects/Project";
import PrivacyPolicy from './components/Privacy/PrivacyPolicy';

import ScrollToTop from "./components/common/ScrollToTop";
import store from "./store";
import TermsAndConditions from "./components/Privacy/TermsAndConditions";

class App extends React.Component {
  componentDidMount() {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute("src", "https://cdn.userway.org/widget.js");
    script.setAttribute("data-account", "nd86SAKeCV");
    script.setAttribute("async", true);
    script.setAttribute("type", "text/javascript");
    head.appendChild(script);
  }
  render() {
    const theme = createMuiTheme({
      direction: "rtl"
    });
    // Configure JSS
    const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
    return (
      <React.Fragment>
        <Provider store={store}>
          <Router>
          <ScrollToTop />
            <ThemeProvider theme={theme}>
              <StylesProvider jss={jss}>
                <Navigation />
                <React.Fragment>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/projects" component={Projects} />
                  <Route exact path="/projects/:id" component={Project} />
                  <Route exact path="/contact-us" component={ContactUs} />
                  <Route exact path="/about-us" component={AboutUs} />
                  <Route exact path="/managment" component={Managment} />
                  <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                  <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
                  <Route
                    exact
                    path="/accessibility"
                    component={Accessibility}
                  />
                </React.Fragment>
                <Footer />
              </StylesProvider>
            </ThemeProvider>
          </Router>
        </Provider>
      </React.Fragment>
    );
  }
}

export default App;
