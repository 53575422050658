import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  formControl: {
    padding: theme.spacing(4, 4)
  },
  pargraph: {
    margin: " 0 0 10px",
    boxShadow: "border-box"
  }
}));

function Accessibility() {
  const classes = useStyles();
  return (
    <Container maxWidth="lg" className={classes.formControl}>
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h3">
          <strong>הצהרת נגישות -&nbsp;מחאמיד תופיק בע"מ</strong>
        </Typography>
      </div>
      <Typography component="p" variant="body1">
        <hr />
        <br />
        <div className="itemFullText">
          <p
            className={classes.pargraph}
            dir="rtl"
            style={{ textAlign: "right" }}
          >
            <span
              style={{
                textDecoration: "underline",
                fontSize: "14pt",
                color: "#000000"
              }}
            >
              <strong>רקע</strong>
            </span>
          </p>
          <p
            className={classes.pargraph}
            dir="rtl"
            style={{ textAlign: "right" }}
          >
            <span style={{ color: "#000000", fontSize: "12pt" }}>
              <strong>
                מחאמיד תופיק בע"מ רואה חשיבות רבה בהענקת חווית גלישה וקריאה
                שוויונית לכלל הגולשים וגולשים עם מוגבלות בכללם. לשם כך הושקעו
                משאבים רבים בהנגשת האתר, במטרה להקל על השימוש בו, וכן להפוך את
                תוכן האתר לזמין יותר עבור אנשים עם מוגבלות.
              </strong>
            </span>
          </p>
          <p
            className={classes.pargraph}
            dir="rtl"
            style={{ textAlign: "right" }}
          >
            <span
              style={{
                textDecoration: "underline",
                fontSize: "14pt",
                color: "#000000"
              }}
            >
              <strong>רמת ההנגשה</strong>
            </span>
          </p>
          <p
            className={classes.pargraph}
            dir="rtl"
            style={{ textAlign: "right" }}
          >
            <span style={{ color: "#000000", fontSize: "12pt" }}>
              <strong>
                <span style={{ fontSize: "12pt" }}>
                  אנו דואגים ומשקיעים משאבים על-מנת שהדפים באתר יעמדו בתקן
                  וקריטריונים של נגישות&nbsp; &nbsp; בדרגה&nbsp;
                </span>
                <span style={{ fontSize: "13.3333px" }}>AA :</span>
              </strong>
            </span>
          </p>
          <p
            className={classes.pargraph}
            dir="rtl"
            style={{ textAlign: "right" }}
          >
            <span style={{ color: "#000000", fontSize: "12pt" }}>
              <strong>Web Content Accessibility Guidelines (WCAG) 2.0</strong>
            </span>
          </p>
          <p
            className={classes.pargraph}
            dir="rtl"
            style={{ textAlign: "right" }}
          >
            <span style={{ color: "#000000", fontSize: "14pt" }}>
              <strong>
                <span style={{ textDecoration: "underline" }}>
                  הנחיות לנגישות תכנים באינטרנט&nbsp;WCAG 2.0
                </span>
              </strong>
            </span>
          </p>
          <p
            className={classes.pargraph}
            dir="rtl"
            style={{ textAlign: "right" }}
          >
            <span style={{ color: "#000000", fontSize: "12pt" }}>
              <strong>
                אנו ממשיכים במאמצים לשפר את נגישות האתר כחלק ממחויבותנו לאפשר
                שימוש בו עבור כלל האוכלוסייה לרבות אנשים עם מוגבלויות.&nbsp;
              </strong>
              &nbsp;
            </span>
          </p>
          <p
            className={classes.pargraph}
            dir="rtl"
            style={{ textAlign: "right" }}
          >
            <span
              style={{
                textDecoration: "underline",
                fontSize: "14pt",
                color: "#000000"
              }}
            >
              <strong>כיצד עובדת ההנגשה באתר?</strong>
            </span>
          </p>
          <p
            className={classes.pargraph}
            dir="rtl"
            style={{ textAlign: "right" }}
          >
            <span style={{ color: "#000000", fontSize: "12pt" }}>
              <strong>
                באתר מוצב תפריט הנגשה. לחיצה על התפריט מאפשרת פתיחת כפתורי
                ההנגשה.לאחר בחירת נושא בתפריט יש להמתין לטעינת הדף.
              </strong>
            </span>
          </p>
          <p
            className={classes.pargraph}
            dir="rtl"
            style={{ textAlign: "right" }}
          >
            <span
              style={{
                textDecoration: "underline",
                fontSize: "14pt",
                color: "#000000"
              }}
            >
              <strong>מגבלות הנגישות</strong>
            </span>
          </p>
          <p
            className={classes.pargraph}
            dir="rtl"
            style={{ textAlign: "right" }}
          >
            <span style={{ color: "#000000", fontSize: "12pt" }}>
              <strong>
                בימים אלו אנו מוסיפים ומבצעים&nbsp;שיפורים שיאפשרו הגברת
                הנגישות, שיפור ושדרוג הזמינות, הידידותיות ונוחות הגלישה על-מנת
                לענות על מירב הצרכים המיוחדים של אנשים עם לקויות.
              </strong>
            </span>
          </p>
          <p
            className={classes.pargraph}
            dir="rtl"
            style={{ textAlign: "right" }}
          >
            <span
              style={{
                textDecoration: "underline",
                fontSize: "14pt",
                color: "#000000"
              }}
            >
              <strong>יצירת קשר</strong>
            </span>
          </p>
          <p
            className={classes.pargraph}
            dir="rtl"
            style={{ textAlign: "right" }}
          >
            <span style={{ color: "#000000" }}>
              <strong>
                <span style={{ fontSize: "12pt" }}>
                  אם במהלך הגלישה באתר נתקלת בבעיה בנושא נגישות נשמח לקבל ממך
                  הערות ובקשות,&nbsp;זאת באמצעות&nbsp;
                  מייל&nbsp;office1@m-tawfik.com ,
                </span>
                &nbsp;
              </strong>
            </span>
            <span style={{ color: "#000000", fontSize: "12pt" }}>
              <strong>
                אנו נעשה ככל שביכולתנו על מנת להנגיש את האתר בצורה המיטבית
                ולענות לפניות בצורה המקצועית האחראית והמהירה ביותר.
              </strong>
            </span>
          </p>
          <p
            className={classes.pargraph}
            dir="rtl"
            style={{ textAlign: "right" }}
          >
            &nbsp;
          </p>
          <p className={classes.pargraph} style={{ textAlign: "right" }}>
            <span style={{ fontSize: "14pt" }} />
          </p>
        </div>
      </Typography>
    </Container>
  );
}

export default Accessibility;
