import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProjectById } from "../../actions/projectsActions";
import Spinner from "../Loader/Spinner";

const useStyles = makeStyles(theme => ({
  cardGrid: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    overflow: "hidden",
    maxHeight: 400
  },
  cardMedia: {
    width: "100%",
    margin: "auto",
    display: "block",
    borderRadius: 4
  },
  cardContent: {
    flexGrow: 1,
    textAlign: "center"
  },
  gridItemFit: {
    width: "100%"
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4, 0, 2)
  }
}));

export default function Managment(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const project = useSelector(state => state.projects.project);
  const loading = useSelector(state => state.projects.loading);

  useEffect(() => {
    dispatch(getProjectById(props.match.params.id));
  }, []);

  return (
    <React.Fragment>
      <main>
        {project === null || loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 300
            }}
          >
            <Spinner />
          </div>
        ) : (
          <React.Fragment>
            <div
              className={classes.heroContent}
              style={{ background: "#fafafa" }}
            >
              <Container maxWidth="lg">
                <Typography
                  component="h4"
                  variant="h4"
                  align="center"
                  color="textPrimary"
                  gutterBottom
                >
                  {project.name}
                </Typography>
              </Container>
            </div>

            <Container className={classes.cardGrid} maxWidth="lg">
              <Grid container spacing={3}>
                <Grid xs={12} sm={12} md={12}>
                  <Paper className={classes.card}>
                    <img
                      className={classes.cardMedia}
                      src={project.cover_image}
                      alt={project.name}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Container>
            <Container className={classes.cardGrid} maxWidth="lg">
              <Typography
                component="h5"
                variant="h5"
                color="textPrimary"
                gutterBottom
              >
                אודות הפרויקט
              </Typography>
              <hr />
              <br />
              <Typography
                component="p"
                variant="body1"
                color="textPrimary"
                gutterBottom
              >
                {project.about}
              </Typography>
            </Container>
          </React.Fragment>
        )}
      </main>
    </React.Fragment>
  );
}
