import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LockOutlinedIcon from "@material-ui/icons/Message";
import { useSelector } from "react-redux";
import wide from "../../images/wide.jpg";

const useStyles = makeStyles(theme => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  cardMedia: {
    paddingTop: "56.25%" // 16:9
  },
  cardContent: {
    flexGrow: 1
  },
  gridItemFit: {
    width: "100%"
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper
    //padding: theme.spacing(8, 0, 6)
  },
  paper: {
    margin: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  formControl: {
    margin: theme.spacing(1),

    width: "100%"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  widthFit: {
    width: "100%"
  },
  input: {
    display: "none"
  }
}));

export default function Album() {
  const classes = useStyles();
  const errors = useSelector(state => state.errors);

  const [state, setState] = React.useState({
    name: "",
    about: ""
  });

  const handleChange = name => event => {
    setState({
      ...state,
      [name]: event.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    const project = new FormData();
    project.set("name", state.name);
    project.set("about", state.about);

    //console.log(...project);
  };

  return (
    <React.Fragment>
      <main>
        <Container
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            minWidth: "100%",
            padding: 0
          }}
        >
          <img src={wide} style={{ width: "100%" }} alt="contact us"></img>
          <Typography
            component="h1"
            variant="h3"
            align="center"
            style={{ position: "absolute", color: "white" }}
            gutterBottom
          >
            יצירת קשר
          </Typography>
        </Container>

        <Container maxWidth="xl">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              שלח הודעה
            </Typography>
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="שם"
                    name="name"
                    autoComplete="name"
                    onChange={handleChange("name")}
                    error={errors !== {} && errors.name ? true : false}
                    helperText={
                      errors !== {} && errors.name ? errors.name : " "
                    }
                  />
                  <TextField
                    style={{ marginTop: 7 }}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="אי-מייל"
                    name="project_number"
                    autoComplete="project_number"
                    onChange={handleChange("project_number")}
                    error={
                      errors !== {} && errors.project_number ? true : false
                    }
                    helperText={
                      errors !== {} && errors.project_number
                        ? errors.project_number
                        : " "
                    }
                  />
                  <TextField
                    style={{ marginTop: 7 }}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="מספר טלפון"
                    name="project_number"
                    autoComplete="project_number"
                    onChange={handleChange("project_number")}
                    error={
                      errors !== {} && errors.project_number ? true : false
                    }
                    helperText={
                      errors !== {} && errors.project_number
                        ? errors.project_number
                        : " "
                    }
                  />

                  <TextField
                    className={classes.widthFit}
                    margin="normal"
                    label="הודעה"
                    name="about"
                    multiline
                    rows="6"
                    onChange={handleChange("about")}
                    defaultValue="הודעה...*"
                    variant="outlined"
                    error={errors !== {} && errors.about ? true : false}
                    helperText={
                      errors !== {} && errors.about ? errors.about : " "
                    }
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    שלח הודעה
                  </Button>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13455.2857506056!2d35.1558546!3d32.5309157!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa2655dc705251bfe!2sMhamid%20Tawfik%20-%20Building%20and%20Development%20Ltd.!5e0!3m2!1sen!2sil!4v1583768085505!5m2!1sen!2sil"
                    frameBorder="0"
                    style={{
                      border: 0,
                      width: "100%",
                      height: "100%",
                      padding: "2%"
                    }}
                    allowFullScreen=""
                    title="map"
                  ></iframe>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </main>
    </React.Fragment>
  );
}
