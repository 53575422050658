import DropDownIcon from "@material-ui/icons/KeyboardArrowDown";
import LanguageIcon from "@material-ui/icons/Translate";
import React from "react";
import { Link, useHistory } from "react-router-dom";

export default function Navigation() {
  const history = useHistory();

  const [state, setState] = React.useState({
    selected: "/",
    prevSelcted: null,
    Otherselected: false,
    checked: false,
    pathname: window.location.pathname
  });

  const onClick = selected => {
    setState({
      ...state,
      selected: selected,
      pathname: history.location.pathname
    });
  };

  const closeCheckBox = () => {
    setState({ ...state, checked: !state.checked });
  };

  return (
    <nav style={{ position: "sticky", top: 0, background: "#fff", zIndex: 5 }}>
      <div className="nav_wrapper">
        <section className="logo_container">
          <Link to="/" className="logo"></Link>
        </section>

        <ul className="main_nav" id="nav_container">
          <li>
            <Link
              to="/"
              onClick={() => onClick("/")}
              className={
                history.location.pathname === "/" ? "selectedLink" : ""
              }
            >
              דף הבית
            </Link>
          </li>
          <li>
            <Link
              to="/projects?category=all"
              onClick={() => onClick("/projects?category=all")}
              className={
                history.location.pathname.match("/projects*")
                  ? "selectedLink"
                  : ""
              }
            >
              פרויקטים
            </Link>
          </li>
          <li>
            <Link
              to="/about-us"
              style={{ display: "flex" }}
              onClick={() => onClick("/about-us")}
              className={
                history.location.pathname.match("/about-us*") ||
                history.location.pathname.match("/managment")
                  ? "selectedLink"
                  : ""
              }
            >
              אודות
              <DropDownIcon></DropDownIcon>
            </Link>
            <ul>
              <li
                className={
                  history.location.pathname.match("/about-us*")
                    ? "selectedLinkInner"
                    : ""
                }
              >
                <Link to="/about-us" onClick={() => onClick("/about-us")}>
                  על החברה
                </Link>
              </li>
              <li
                className={
                  history.location.pathname === "/managment"
                    ? "selectedLinkInner"
                    : ""
                }
              >
                <Link to="/managment" onClick={() => onClick("/managment")}>
                  הנהלה
                </Link>
              </li>
            </ul>
          </li>
          <li to="/contact-us">
            <Link
              to="/contact-us"
              onClick={() => {
                onClick("/contact-us");
              }}
              className={
                history.location.pathname === "/contact-us"
                  ? "selectedLink"
                  : ""
              }
            >
              {" "}
              יצירת קשר
            </Link>
          </li>
          <li
            style={{
              marginRight: "auto",
              textAlign: "left",
              display: "flex",
              alignItems: "center"
            }}
          >
            <Link to="#" style={{ display: "flex" }}>
              <LanguageIcon></LanguageIcon>
              <p style={{ padding: "0px 7px 0px 0px" }}> עברית</p>
              <DropDownIcon></DropDownIcon>
            </Link>
            <ul style={{ textAlign: "right" }}>
              <li>
                <Link to="#">English</Link>
              </li>
              {/* <li>
                <Link to="#">العربيه</Link>
              </li> */}
            </ul>
          </li>
        </ul>
        <section className="hamburger_nav">
          <section id="hamburger">
            <input
              type="checkbox"
              checked={state.checked}
              onClick={closeCheckBox}
              onChange={closeCheckBox}
            />
            <span></span>
            <span></span>
            <span></span>
            <ul id="hamburger_menu">
              <li onClick={closeCheckBox}>
                <Link to="/"></Link>
              </li>
              <li onClick={closeCheckBox}>
                <Link
                  to="/"
                  onClick={() => onClick("/")}
                  className={
                    history.location.pathname === "/"
                      ? "selectedLinkBurger"
                      : ""
                  }
                >
                  דף הבית
                </Link>
              </li>
              <li onClick={closeCheckBox}>
                <Link
                  to="/projects?category=all"
                  onClick={() => onClick("/projects?category=all")}
                  className={
                    history.location.pathname.match("/projects*")
                      ? "selectedLinkBurger"
                      : ""
                  }
                >
                  פרויקטים
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  style={{ display: "flex" }}
                  className={
                    history.location.pathname.match("/about-us*") ||
                    history.location.pathname.match("/managment")
                      ? "selectedLinkBurger"
                      : ""
                  }
                >
                  אודות
                  <DropDownIcon></DropDownIcon>
                </Link>
                <ul>
                  <li
                    onClick={closeCheckBox}
                    className={
                      history.location.pathname === "/about-us"
                        ? "selectedLinkBurgerInner"
                        : ""
                    }
                  >
                    <Link to="/about-us" onClick={() => onClick("/about-us")}>
                      על החברה
                    </Link>
                  </li>
                  <li
                    onClick={closeCheckBox}
                    className={
                      history.location.pathname === "/managment"
                        ? "selectedLinkBurgerInner"
                        : ""
                    }
                  >
                    <Link to="/managment" onClick={() => onClick("/managment")}>
                      הנהלה
                    </Link>
                  </li>
                </ul>
              </li>

              <li onClick={closeCheckBox}>
                <Link
                  to="/contact-us"
                  onClick={() => onClick("/contact-us")}
                  className={
                    history.location.pathname === "/contact-us"
                      ? "selectedLinkBurger"
                      : ""
                  }
                >
                  יצירת קשר
                </Link>
              </li>
              <li>
                <Link to="#" style={{ display: "flex" }}>
                  <LanguageIcon></LanguageIcon>
                  <p style={{ padding: "0px 7px 0px 0px" }}> עברית</p>
                  <DropDownIcon></DropDownIcon>
                </Link>
                <ul style={{ textAlign: "right" }}>
                  <li onClick={closeCheckBox}>
                    <Link to="#">English</Link>
                  </li>
                  {/* <li onClick={closeCheckBox}>
                    <Link to="#">العربيه</Link>
                  </li> */}
                </ul>
              </li>
            </ul>
          </section>
        </section>
      </div>
    </nav>
  );
}

/* <Link
              to="#"
              style={{ display: "flex" }}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <LanguageIcon></LanguageIcon>
              <span style={{ padding: "0px 7px 0px 0px" }}> עברית</span>
              <DropDownIcon></DropDownIcon>
            </Link>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
              getContentAnchorEl={null}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose} style={{ minWidth: 130 }}>
                {" "}
                English{" "}
              </MenuItem>
              <MenuItem onClick={handleClose}> العربيه </MenuItem>
            </Menu> */
