import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import australia from "../../images/svg/australia.svg";
import risk from "../../images/svg/risk.svg";
import school from "../../images/svg/school.svg";
import tenant from "../../images/svg/tenant.svg";
import train from "../../images/svg/train.svg";
import urban from "../../images/svg/urban.svg";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10
  },
  media: {
    padding: 4,
    height: "72px",
    width: "72px",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundPosition: "center",
    backgorundSize: "90% 90%",
    marginTop: 20
  },
  cardGrid: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8)
  }
}));

export default function MediaCard() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Container className={classes.cardGrid} maxWidth="lg" justify="center">
        <h1 style={{ textAlign: "center", marginBottom: 25 }}>מחאמיד תאופיק</h1>
        <Grid container justify="center">
          <Grid item xs={6} sm={4} md={4}>
            <Card
              className={classes.root}
              component={NavLink}
              to="/projects?category=cities_and_kibbutzim"
              elevation={0}
            >
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={urban}
                  title="Contemplative Reptile"
                  justify="center"
                />

                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h6"
                    align="center"
                    component="h3"
                  >
                    עירים וקיבוצים
                  </Typography>
                  {/*   <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    align="center"
                  >
                    עירים וקיבוצים
                  </Typography> */}
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={6} sm={4} md={4}>
            <Card
              className={classes.root}
              elevation={0}
              component={NavLink}
              to="/projects?category=apartments_and_tenants"
              justify="center"
            >
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={tenant}
                  title="Contemplative Reptile"
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="h3"
                    align="center"
                  >
                    דירות ודיירים
                  </Typography>
                  {/* <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    align="center"
                  >
                    דירות ודיירים
                  </Typography> */}
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={6} sm={4} md={4}>
            <Card
              className={classes.root}
              component={NavLink}
              to="/projects?category=colleges_and_public_buildings"
              elevation={0}
            >
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={school}
                  title="Contemplative Reptile"
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="h3"
                    align="center"
                  >
                    מכללות ומבנים ציבוריים
                  </Typography>
                  {/*   <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    align="center"
                  >
                    מכללות ומבנים ציבוריים
                  </Typography> */}
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={6} sm={4} md={4}>
            <Card
              className={classes.root}
              component={NavLink}
              to="/projects?category=train_stations"
              elevation={0}
            >
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={train}
                  title="Contemplative Reptile"
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="h3"
                    align="center"
                  >
                    תחנות רכבת
                  </Typography>
                  {/*   <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    align="center"
                  >
                    תחנות רכבת
                  </Typography> */}
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={6} sm={4} md={4}>
            <Card
              className={classes.root}
              elevation={0}
              component={NavLink}
              to="/projects?category=entrepreneurship"
              justify="center"
            >
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={risk}
                  title="Contemplative Reptile"
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="h3"
                    align="center"
                  >
                    יזמות
                  </Typography>
                  {/*       <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    align="center"
                  >
                    יזמות
                  </Typography> */}
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={6} sm={4} md={4}>
            <Card
              className={classes.root}
              elevation={0}
              component={NavLink}
              to="/projects?category=culture_halls"
              justify="center"
            >
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={australia}
                  title="Contemplative Reptile"
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="h3"
                    align="center"
                  >
                    היכלי תרבות
                  </Typography>
                  {/*  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    align="center"
                  >
                    היכלי תרבות
                  </Typography> */}
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
