import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 300
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  container: {
    display: "flex",
    justifyContent: "center"
  }
}));

export default function NativeSelects() {
  const classes = useStyles();
  const history = useHistory();
  let filter = history.location.search.split("=")[1];
  const [state, setState] = React.useState({
    category: history.location.search.split("=")[1]
  });

  const handleChange = event => {
    const name = event.target.name;
    history.push({
      pathname: "/projects",
      search: `?category=${event.target.value}`
    });
    setState({
      ...state,
      [name]: event.target.value
    });
  };

  return (
    <div className={classes.container}>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor="outlined-category-native-simple">
          סנן לפי קטגוריה
        </InputLabel>
        <Select
          native
          value={filter}
          onChange={handleChange}
          label="סנן לפי קטגוריה"
          inputProps={{
            name: "category",
            id: "outlined-category-native-simple"
          }}
        >
          <option value={"all"}> הכל</option>
          <option value={"cities_and_kibbutzim"}> עירים וקיבוצים</option>
          <option value={"apartments_and_tenants"}> דירות ודיירים</option>
          <option value={"colleges_and_public_buildings"}>
            מכללות ומבנים ציבוריים
          </option>
          <option value={"train_stations"}> תחנות רכבת</option>
          <option value={"culture_halls"}> היכלי תרבות</option>
          <option value={"entrepreneurship"}> יזמות</option>
        </Select>
      </FormControl>
    </div>
  );
}
