import axios from "axios";
import { URL } from "./consts";
import {
  GET_ERRORS,
  GET_PROJECTS,
  PROJECT_LOADING,
  CLEAR_ERRORS,
  GET_PROJECT,
  GET_RECENT_PROJECTS
} from "./types";

// Get all projects
export const getAllProjects = () => dispatch => {
  setProjectLoading();
  axios
    .get(`${URL}/projects`)
    .then(result => {
      dispatch({
        type: GET_PROJECTS,
        payload: result.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Get recent projects
export const getRecentProjects = () => dispatch => {
  setProjectLoading();
  axios
    .get(`${URL}/projects/recent`)
    .then(result => {
      dispatch({
        type: GET_RECENT_PROJECTS,
        payload: result.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Get project by id
export const getProjectById = id => dispatch => {
  dispatch(setProjectLoading());
  axios
    .get(`${URL}/projects/${id}`)
    .then(result => {
      dispatch({
        type: GET_PROJECT,
        payload: result.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_PROJECT,
        payload: null
      });
    });
};

// Set loading state
export const setProjectLoading = () => {
  return {
    type: PROJECT_LOADING
  };
};

// Clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};
