import { GET_PROJECTS, PROJECT_LOADING, GET_PROJECT,GET_RECENT_PROJECTS } from "../actions/types";

const initialState = {
  projects: [],
  recent_projects:[],
  project: {},
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PROJECTS:
      return {
        ...state,
        projects: action.payload,
        loading: false
      };
      case GET_RECENT_PROJECTS:
        return {
          ...state,
          recent_projects: action.payload,
          loading: false
        };
    case PROJECT_LOADING:
      return {
        ...state,
        loading: true
      };

    case GET_PROJECT:
      return {
        ...state,
        project: action.payload,
        loading: false
      };

    default:
      return state;
  }
}
